import { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Box,
  Paper,
  Typography,
  FormControl,
  InputAdornment,
  InputLabel,
  InputBase,
  Button,
  SvgIcon,
  Link,
} from "@material-ui/core";

import { trim } from "../../helpers";
import { ReactComponent as OhmImg } from "../../assets/tokens/asg-large.svg";
import { ReactComponent as sOhmImg } from "../../assets/tokens/sasg.svg";
import { changeSwap, changeApproval } from "../../actions/Swap.actions";

import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down.svg";
import { ReactComponent as Swap } from "../../assets/icons/swap.svg";
import { NETWORK_ID } from "../../constants";

import "./redistribution.scss";

function Redistribution({ provider, address, web3Modal, loadWeb3Modal }) {
  const dispatch = useDispatch();

  const [view, setView] = useState(0);
  const [quantity, setQuantity] = useState();

  const ohmBalance = useSelector(state => {
    return state.app.balances && state.app.balances.ohm;
  });

  const sohmBalance = useSelector(state => {
    return state.app.balances && state.app.balances.sohm;
  });

  const swapAllowance = useSelector(state => {
    return state.app.swapAllowance;
  });

  const setMax = () => {
    setQuantity(ohmBalance);
  };

  const onSeekApproval = async () => {
    await dispatch(changeApproval({ address, provider, networkID: NETWORK_ID }));
  };

  const onChangeSwap = async () => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(quantity) || quantity === 0 || quantity === "") {
      // eslint-disable-next-line no-alert
      alert("Please enter a value!");
    } else {
      await dispatch(changeSwap({ address, value: quantity.toString(), provider, networkID: NETWORK_ID }));
    }
  };

  let modalButton = [];

  if (web3Modal) {
    modalButton.push(
      <Button
        variant="contained"
        color="primary"
        className="connect-button uniform-button"
        onClick={loadWeb3Modal}
        key={2}
      >
        Connect to a wallet
      </Button>,
    );
  }

  const trimmedSOHMBalance = trim(sohmBalance, 4);
  return (
    <div id="stake-view">
      <Grid container spacing={3} className="maxWebAppWidth">
        <Grid item xs={12} md={8}>
          <Paper className="ohm-card action-pannel">
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Typography variant="h1" color="textPrimary">
                  <Box fontWeight={500} mb={2}>
                    Fair Redistribution
                  </Box>
                </Typography>
                <Typography variant="body1">
                  <Box component="span" className="textTertiary" mb={1}>
                    Swap your ASG for a fixed DAI amount. Learn more about the Fair Redistribution{" "}
                    <Link href={"https://docs.asgarddao.finance/fair-redistribution/swap-asg-for-dai"} target="_blank">
                      here
                    </Link>
                    .
                  </Box>
                </Typography>
              </Grid>
              <Grid item md={12}>
                <Typography variant="h3" color="textSecondary">
                  DAI per ASG
                </Typography>
                <Typography variant="h2">
                  <Box component="span" mb={2} fontWeight={400}>
                    17.19 DAI
                  </Box>
                </Typography>
              </Grid>
              <Grid item md={12}>
                {!address ? (
                  <div className="stake-wallet-notification">
                    <div className="wallet-menu" id="wallet-menu">
                      {modalButton}
                    </div>
                    <Typography variant="body1" color="textSecondary">
                      <Box mt={2}>Connect your wallet to stake ASG</Box>
                    </Typography>
                  </div>
                ) : (
                  <>
                    <Box className="stake-action-area">
                      <Box mt={0} alignItems="center">
                        <Box>
                          <Typography variant="h1">
                            <Box mb={2} fontWeight={500}>
                              Swap ASG for DAI
                            </Box>
                          </Typography>
                        </Box>
                        <Box
                          mb={2}
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          className="textTertiary"
                        >
                          <Box display="flex" flexDirection="row" alignItems="center">
                            <SvgIcon component={ArrowDown} viewBox="0 0 16 16" htmlColor="#7F7F7F" />
                            <Typography style={{ marginLeft: 10 }} variant="body1">
                              <Box component="span" ml={0.5}>
                                Add ASG
                              </Box>
                            </Typography>
                          </Box>
                          <Typography variant="body1">Balance {trim(ohmBalance)} ASG</Typography>
                        </Box>
                        <FormControl className="formInput" variant="outlined" color="primary" fullWidth>
                          <InputLabel htmlFor="amount-input"></InputLabel>
                          <InputBase
                            id="amount-input"
                            type="number"
                            placeholder="Enter an amount"
                            className="stake-input"
                            autoFocus
                            value={quantity}
                            onChange={e => setQuantity(e.target.value)}
                            labelwidth={0}
                            endAdornment={
                              <InputAdornment position="end">
                                <Button className="inputMaxButton" variant="text" onClick={setMax}>
                                  Max
                                </Button>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                        <Box className="swapIcon">
                          <SvgIcon
                            component={Swap}
                            viewBox="0 0 42 42"
                            style={{
                              height: "42px",
                              width: "42px",
                              border: "2px solid rgba(32, 35, 48, 0.5)",
                              borderRadius: "50%",
                            }}
                          />
                        </Box>
                        <FormControl className="formInput" variant="outlined" color="primary" fullWidth>
                          <InputLabel htmlFor="amount-input"></InputLabel>
                          <InputBase
                            id="amount-input"
                            type="number"
                            placeholder="Enter an amount"
                            className="stake-input"
                            value={(parseFloat(quantity) * 17.19).toFixed(9)}
                            labelwidth={0}
                            disabled
                          />
                        </FormControl>
                        <Box mt={2}>
                          {address && swapAllowance > 0 ? (
                            <Button
                              className="unstake-button"
                              variant="contained"
                              color="primary"
                              onClick={() => onChangeSwap()}
                            >
                              Swap ASG
                            </Button>
                          ) : (
                            <Button
                              className="approve-button"
                              variant="contained"
                              color="primary"
                              onClick={onSeekApproval}
                            >
                              Approve
                            </Button>
                          )}
                        </Box>
                      </Box>

                      <div className="help-text">
                        {address && !(swapAllowance > 0) && view === 0 && (
                          <Box mt={2}>
                            <Typography variant="body2" color="textSecondary" align="center">
                              The "Approve" transaction is only needed when swapping for the first time; subsequent
                              swaps only requires you to perform the "Swap" transaction.
                            </Typography>
                          </Box>
                        )}
                      </div>
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className={`ohm-card`}>
            <Box mb={1.5}>
              <Typography variant="h2" color="textPrimary">
                <Box component="span" fontWeight={500}>
                  Staked
                </Box>
              </Typography>
            </Box>
            <Box className="balance">
              <SvgIcon component={sOhmImg} viewBox="0 0 64 64" style={{ height: "64px", width: "64px" }} />
              <Box ml={2} className="balance-labels">
                <Typography variant="h3" color="textPrimary">
                  {trimmedSOHMBalance}
                </Typography>
                <Typography variant="h3" color="textSecondary">
                  sASG
                </Typography>
              </Box>
            </Box>
            <Box mt={7} mb={1.5}>
              <Typography variant="h2" color="textPrimary">
                <Box component="span" fontWeight={500}>
                  Unstaked
                </Box>
              </Typography>
            </Box>
            <Box className="balance">
              <SvgIcon component={OhmImg} viewBox="0 0 64 64" style={{ height: "64px", width: "64px" }} />
              <Box ml={2} className="balance-labels">
                <Typography variant="h3" color="textPrimary">
                  {trim(ohmBalance)}
                </Typography>
                <Typography variant="h3" color="textSecondary">
                  ASG
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}></Grid>
      </Grid>
    </div>
  );
}

export default Redistribution;
