import RoobertWOFF from "../assets/fonts/Roobert-Regular.woff";
import RoobertBoldWOFF from "../assets/fonts/Roobert-Bold.woff";
import RoobertSemiBoldWOFF from "../assets/fonts/Roobert-SemiBold.woff";
import RoobertItalicWOFF from "../assets/fonts/Roobert-Italic.woff";
import RoobertLightWOFF from "../assets/fonts/Roobert-Light.woff";
import RoobertMediumWOFF from "../assets/fonts/Roobert-Medium.woff";

const roobert = {
  fontFamily: "Roobert",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('Roobert'),
		local('Roobert-Regular'),
		url(${RoobertWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const roobertLight = {
  fontFamily: "Roobert",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 300,
  src: `
		local('Roobert'),
		local('Roobert-Light'),
		url(${RoobertLightWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const roobertMedium = {
  fontFamily: "Roobert",
  fontStyle: "medium",
  fontDisplay: "swap",
  fontWeight: 500,
  src: `
		local('Roobert'),
		local('Roobert-Medium'),
		url(${RoobertMediumWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const roobertSemiBold = {
  fontFamily: "Roobert",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 600,
  src: `
		local('Roobert-SemiBold'),
		local('Roobert-SemiBold'),
		url(${RoobertSemiBoldWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const roobertBold = {
  fontFamily: "Roobert",
  fontStyle: "bold",
  fontDisplay: "swap",
  fontWeight: 700,
  src: `
		local('Roobert-Bold'),
		local('Roobert-Bold'),
		url(${RoobertBoldWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const roobertItalic = {
  fontFamily: "Roobert",
  fontStyle: "italic",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('Roobert-Italic'),
		local('Roobert-Italic'),
		url(${RoobertItalicWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const fonts = [roobert, roobertLight, roobertMedium, roobertBold, roobertItalic];

export default fonts;
