import {
  Typography,
  Box,
  Modal,
  Paper,
  SvgIcon,
  IconButton,
  FormControl,
  OutlinedInput,
  InputLabel,
  InputAdornment,
} from "@material-ui/core";
import { ReactComponent as XIcon } from "../../assets/icons/v1.2/x.svg";
import "./bondSettings.scss";

function AdvancedSettings({
  open,
  handleClose,
  slippage,
  recipientAddress,
  onRecipientAddressChange,
  onSlippageChange,
}) {
  return (
    <Modal id="hades" open={open} onClose={handleClose} hideBackdrop>
      <Paper className="ohm-card ohm-popover">
        <Box p={2} display="flex" flexDirection="row" alignItems="center">
          <IconButton onClick={handleClose}>
            <SvgIcon color="primary" component={XIcon} />
          </IconButton>
          <Typography variant="h3">
            <Box fontWeight={500}>Advanced Settings</Box>
          </Typography>
        </Box>

        <Box pl={4} pr={4} pb={4}>
          <Box mb={1}>
            <Typography variant="body1" color="textPrimary">
              Slippage
            </Typography>
          </Box>
          <FormControl variant="outlined" color="primary" fullWidth>
            <OutlinedInput
              id="slippage"
              value={slippage}
              onChange={onSlippageChange}
              type="number"
              max="100"
              min="100"
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
            />
            <Box mt={1}>
              <div className="help-text">
                <Typography variant="body2" color="textSecondary">
                  Transaction may revert if price changes by more than slippage %.
                </Typography>
              </div>
            </Box>
          </FormControl>

          <Box mt={4} mb={1}>
            <Typography variant="body1" color="textPrimary">
              Recipient's Address
            </Typography>
          </Box>
          <FormControl variant="outlined" color="primary" fullWidth>
            <OutlinedInput id="recipient" value={recipientAddress} onChange={onRecipientAddressChange} type="text" />
            <Box mt={1}>
              <div className="help-text">
                <Typography variant="body2" color="textSecondary">
                  Choose recipient address. By default, this is your currently connected address
                </Typography>
              </div>
            </Box>
          </FormControl>
        </Box>
      </Paper>
    </Modal>
  );
}

export default AdvancedSettings;
