import { useSelector } from "react-redux";
import { trim, bondName, lpURL, isBondLP, priceUnits } from "../../helpers";
import BondLogo from "../../components/BondLogo";
import { Button, Link, Paper, Typography, TableRow, TableCell, SvgIcon, Slide, Box } from "@material-ui/core";
import { ReactComponent as ArrowUp } from "../../assets/icons/arrow-up.svg";
import { NavLink, useHistory } from "react-router-dom";
import "./choosebond.scss";

export function BondDataCard({ bond }) {
  const bondPrice = useSelector(state => {
    return state.bondingFairLaunch[bond] && state.bondingFairLaunch[bond].bondPrice;
  });
  const bondDiscount = useSelector(state => {
    return state.bondingFairLaunch[bond] && state.bondingFairLaunch[bond].bondDiscount;
  });
  const bondPurchased = useSelector(state => {
    return state.bondingFairLaunch[bond] && state.bondingFairLaunch[bond].purchased;
  });

  return (
    <Slide direction="up" in={true}>
      <Paper id={`${bond}--bond`} className="bond-data-card ohm-card">
        <div className="bond-pair">
          <BondLogo bond={bond} />
          <div className="bond-name">
            <Typography variant="h3">{bondName(bond)}</Typography>
            {isBondLP(bond) && (
              <div>
                <Link href={lpURL(bond)} target="_blank">
                  <Typography variant="subtitle1" color="textSecondary">
                    <SvgIcon component={ArrowUp} htmlColor="#BFBFBF" />
                    Get Liquidity Tokens
                  </Typography>
                </Link>
              </div>
            )}
          </div>
        </div>

        <div className="data-row">
          <Typography>Purchased</Typography>
          <Typography>
            {bondPurchased &&
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              }).format(bondPurchased)}
          </Typography>
        </div>
        <Link component={NavLink} to={`/bonds-fair-launch/${bond}`}>
          <Button variant="outlined" color="primary" fullWidth>
            <Typography variant="h5">Bond {bondName(bond)}</Typography>
          </Button>
        </Link>
      </Paper>
    </Slide>
  );
}

export function BondTableData({ bond }) {
  const bondPrice = useSelector(state => {
    return state.bondingFairLaunch[bond] && state.bondingFairLaunch[bond].bondPrice;
  });
  const bondDiscount = useSelector(state => {
    return state.bondingFairLaunch[bond] && state.bondingFairLaunch[bond].bondDiscount;
  });
  const bondPurchased = useSelector(state => {
    return state.bondingFairLaunch[bond] && state.bondingFairLaunch[bond].purchased;
  });
  let history = useHistory();

  const handleClick = () => {
    history.push(`/bonds-fair-launch/${bond}`);
  };
  return (
    <TableRow onClick={handleClick} id={`${bond}--bond`}>
      <TableCell align="left">
        <Box display="flex">
          <BondLogo bond={bond} />
          <div className="bond-name">
            <Typography variant="h3">{`Fair Launch ${bondName(bond)}`}</Typography>
            {isBondLP(bond) && (
              <Link color="primary" href={lpURL(bond)} target="_blank">
                <Box display="flex" flexDirection="row" alignItems="center" className="textTertiary">
                  <Typography variant="body2">
                    <Box component="span" mr={0.25}>
                      Get Liquidity Tokens
                    </Box>
                  </Typography>
                  <SvgIcon
                    component={ArrowUp}
                    viewBox="0 0 16 16"
                    style={{ height: "16px", width: "16px" }}
                    htmlColor="#7F7F7F"
                  />
                </Box>
              </Link>
            )}
          </div>
        </Box>
      </TableCell>
      <TableCell align="right">
        <Typography variant="h3" color="textSecondary">
          {bondPurchased &&
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }).format(bondPurchased)}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
