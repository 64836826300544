import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  FormControl,
  Box,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Button,
  SvgIcon,
  InputBase,
} from "@material-ui/core";
import { shorten, trim, secondsUntilBlock, prettifySeconds } from "../../helpers";
import { changeApproval, calcBondDetails, calculateUserBondDetails, bondAsset } from "../../actions/Bond.actions.js";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down.svg";
import { BONDS, NETWORK_ID } from "../../constants";
import { local } from "web3modal";

function BondPurchase({ provider, address, bond, slippage }) {
  const dispatch = useDispatch();

  const [recipientAddress, setRecipientAddress] = useState(address);
  const [quantity, setQuantity] = useState();

  const currentBlock = useSelector(state => {
    return state.app.currentBlock;
  });

  const vestingTerm = useSelector(state => {
    return state.bonding[bond] && state.bonding[bond].vestingBlock;
  });

  const bondDiscount = useSelector(state => {
    return state.bonding[bond] && state.bonding[bond].bondDiscount;
  });
  const maxBondPrice = useSelector(state => {
    return state.bonding[bond] && state.bonding[bond].maxBondPrice;
  });
  const interestDue = useSelector(state => {
    return state.bonding[bond] && state.bonding[bond].interestDue;
  });
  const pendingPayout = useSelector(state => {
    return state.bonding[bond] && state.bonding[bond].pendingPayout;
  });
  const debtRatio = useSelector(state => {
    return state.bonding[bond] && state.bonding[bond].debtRatio;
  });
  const bondQuote = useSelector(state => {
    return state.bonding[bond] && state.bonding[bond].bondQuote;
  });
  const balance = useSelector(state => {
    return state.bonding[bond] && state.bonding[bond].balance;
  });
  const allowance = useSelector(state => {
    return state.bonding[bond] && state.bonding[bond].allowance;
  });

  const hasEnteredAmount = () => {
    return !(isNaN(quantity) || quantity === 0 || quantity === "");
  };

  const vestingPeriod = () => {
    const vestingBlock = parseInt(currentBlock) + parseInt(vestingTerm);
    const seconds = secondsUntilBlock(currentBlock, vestingBlock);
    return prettifySeconds(seconds, "day");
  };

  async function onBond() {
    console.log("slippage = ", slippage);
    console.log("recipientAddress = ", recipientAddress);

    if (quantity === "") {
      alert("Please enter a value!");
    } else if (isNaN(quantity)) {
      alert("Please enter a valid value!");
    } else if (interestDue > 0 || pendingPayout > 0) {
      const shouldProceed = window.confirm(
        "You have an existing bond. Bonding will reset your vesting period and forfeit rewards. We recommend claiming rewards first or using a fresh wallet. Do you still want to proceed?",
      );
      if (shouldProceed) {
        await dispatch(
          bondAsset({
            value: quantity,
            slippage,
            bond,
            networkID: NETWORK_ID,
            provider,
            address: recipientAddress || address,
          }),
        );
      }
    } else {
      await dispatch(
        bondAsset({
          value: quantity,
          slippage,
          bond,
          networkID: NETWORK_ID,
          provider,
          address: recipientAddress || address,
        }),
      );
    }
  }

  const hasAllowance = useCallback(() => {
    return allowance > 0;
  }, [allowance]);

  const setMax = () => {
    setQuantity(balance.toString());
  };

  const balanceUnits = () => {
    if (bond.indexOf("_lp") >= 0) return "LP";
    else if (bond === BONDS.dai) return "DAI";
    else if (bond === BONDS.ust) return "wUST";
    else return "FRAX";
  };

  async function loadBondDetails() {
    if (provider) await dispatch(calcBondDetails({ bond, value: quantity, provider, networkID: NETWORK_ID }));

    if (provider && address) {
      await dispatch(calculateUserBondDetails({ address, bond, provider, networkID: NETWORK_ID }));
    }
  }

  useEffect(() => {
    loadBondDetails();
    if (address) setRecipientAddress(address);
  }, [provider, quantity, address]);

  const onSeekApproval = async () => {
    await dispatch(changeApproval({ address, bond, provider, networkID: NETWORK_ID }));
  };

  return (
    <Box pl={4} pr={4} pb={4} className="DELETE-ME">
      <Box mt={3} alignItems="center">
        <Box>
          <Typography variant="h1">
            <Box mb={2} fontWeight={500}>
              Bond
            </Box>
          </Typography>
        </Box>
      </Box>
      <Box mb={2} display="flex" flexDirection="row" justifyContent="space-between" className="textTertiary">
        <Box display="flex" flexDirection="row" alignItems="center">
          <SvgIcon component={ArrowDown} viewBox="0 0 16 16" htmlColor="#7F7F7F" />
          <Typography variant="body1">
            <Box ml={0.5}>Add {balanceUnits()}</Box>
          </Typography>
        </Box>
        <Typography variant="body1">
          Balance {trim(balance, 4)} {balanceUnits()}
        </Typography>
      </Box>
      <FormControl className="formInput" variant="outlined" color="primary" fullWidth="true">
        <InputLabel htmlFor="outlined-adornment-amount"></InputLabel>
        <InputBase
          id="outlined-adornment-amount"
          type="number"
          placeholder="Enter an amount"
          className="stake-input"
          autoFocus="true"
          value={quantity}
          onChange={e => setQuantity(e.target.value)}
          labelwidth={0}
          endAdornment={
            <InputAdornment position="end">
              <Button className="inputMaxButton" variant="text" onClick={setMax}>
                Max
              </Button>
            </InputAdornment>
          }
        />
      </FormControl>

      <Box pt={2}>
        <Typography variant="h3" color="textPrimary">
          <Box fontWeight={400}>Bond Review</Box>
        </Typography>
        <Box mt={2}>
          <Box mb={1} display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="body1" color="textSecondary">
              You Will Get
            </Typography>
            <Typography id="bond-value-id" variant="body1">
              {trim(bondQuote, 4) || ""} ASG
            </Typography>
          </Box>
          <Box mb={1} display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="body1" color="textSecondary">
              Max You Can Buy
            </Typography>
            <Typography id="bond-value-id" variant="body1">
              {trim(maxBondPrice, 4) || ""} ASG
            </Typography>
          </Box>
          <Box mb={1} display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="body1" color="textSecondary">
              Vesting Term
            </Typography>
            <Typography variant="body1">{vestingPeriod()}</Typography>
          </Box>
          {recipientAddress !== address && (
            <Box mb={1} display="flex" flexDirection="row" justifyContent="space-between">
              <Typography variant="body1" color="textSecondary">
                Recipient
              </Typography>
              <Typography variant="body1">{shorten(recipientAddress)}</Typography>
            </Box>
          )}
        </Box>
      </Box>

      <Box mt={3}>
        {hasAllowance() ? (
          <Button variant="contained" color="primary" id="bond-btn" className="bond-button" onClick={onBond}>
            Bond
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            id="bond-approve-btn"
            className="approve-button"
            onClick={onSeekApproval}
          >
            Approve
          </Button>
        )}
      </Box>

      {!hasAllowance() && (
        <Box mt={2}>
          <div className="help-text">
            <Typography variant="body2" color="textSecondary" align="center">
              The "Approve" transaction is only needed when bonding for the first time; subsequent bonding only requires
              you to perform the "Bond" transaction.
            </Typography>
          </div>
        </Box>
      )}
    </Box>
  );
}

export default BondPurchase;
