import { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import BondLogo from "../../components/BondLogo";
import AdvancedSettings from "./AdvancedSettings";
import { bondName } from "../../helpers";
import { Typography, IconButton, SvgIcon, Link, Box } from "@material-ui/core";
import { ReactComponent as SettingsIcon } from "../../assets/icons/v1.2/settings.svg";
import { ReactComponent as XIcon } from "../../assets/icons/v1.2/x.svg";
import useEscape from "../../hooks/useEscape";

function BondHeader({ bond, slippage, recipientAddress, onRecipientAddressChange, onSlippageChange }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let history = useHistory();

  useEscape(() => {
    if (open) handleClose;
    else history.push("/bonds");
  });

  return (
    <div className="bond-header">
      <Box
        px={4}
        py={3}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        className="bondHeader"
      >
        <Link component={NavLink} to="/bonds" className="cancel-bond">
          <Box display="flex" alignItems="center">
            <SvgIcon color="primary" component={XIcon} />
          </Box>
        </Link>

        <div className="bond-header-logo">
          <BondLogo bond={bond} />
          <Box ml={1}>
            <Typography variant="h1">
              <Box fontWeight={500}>{bondName(bond)}</Box>
            </Typography>
          </Box>
        </div>

        <div className="bond-settings">
          <IconButton onClick={handleOpen}>
            <SvgIcon color="primary" component={SettingsIcon} />
          </IconButton>
          <AdvancedSettings
            open={open}
            handleClose={handleClose}
            slippage={slippage}
            recipientAddress={recipientAddress}
            onRecipientAddressChange={onRecipientAddressChange}
            onSlippageChange={onSlippageChange}
          />
        </div>
      </Box>
    </div>
  );
}

export default BondHeader;
