import { ethers } from "ethers";
import { addresses, Actions } from "../constants";
import { abi as ierc20Abi } from "../abi/IERC20.json";

const fairDistribAbi = ["function swap(uint256 _amount) public"];
const fairSwapContractAddress = "0xF30879166657628E3173e09c529f3D53BfE4Ba5b";

export const fetchSwapSuccess = payload => ({
  type: Actions.FETCH_SWAP_SUCCESS,
  payload,
});

export const changeApproval =
  ({ provider, address, networkID }) =>
  async dispatch => {
    if (!provider) {
      alert("Please connect your wallet!");
      return;
    }

    const signer = provider.getSigner();
    const ohmContract = await new ethers.Contract(addresses[networkID].OHM_ADDRESS, ierc20Abi, signer);

    let approveTx;
    try {
      approveTx = await ohmContract.approve(
        fairSwapContractAddress,
        ethers.utils.parseUnits("1000000000", "gwei").toString(),
      );
      //TODO LANCER petit roue qui tourne a coté du mot "approve" sur le bouton pour faire patienter
      await approveTx.wait();
      //TODO LANCER petit roue qui tourne a coté du mot "approve" sur le bouton pour faire patienter
    } catch (error) {
      alert(error.message);
      return;
    }

    const swapAllowance = await ohmContract.allowance(address, fairSwapContractAddress);
    return dispatch(
      fetchSwapSuccess({
        swapAllowance: +swapAllowance,
      }),
    );
  };

export const changeSwap =
  ({ value, provider, address, networkID }) =>
  async dispatch => {
    if (!provider) {
      alert("Please connect your wallet!");
      return;
    }

    const signer = provider.getSigner();
    const signerAddress = await signer.getAddress();
    const fairSwap = await new ethers.Contract(fairSwapContractAddress, fairDistribAbi, signer);

    try {
      const swapTx = await fairSwap.swap(ethers.utils.parseUnits(value, "gwei"));
      await swapTx.wait();
    } catch (error) {
      if (error.code === -32603 && error.message.indexOf("ds-math-sub-underflow") >= 0) {
        alert("You may be trying to swap more than your balance! Error code: 32603. Message: ds-math-sub-underflow");
      } else {
        alert(error.message);
      }
      return;
    }

    const ohmContract = new ethers.Contract(addresses[networkID].OHM_ADDRESS, ierc20Abi, provider);
    const ohmBalance = await ohmContract.balanceOf(address);
    const sohmContract = new ethers.Contract(addresses[networkID].SOHM_ADDRESS, ierc20Abi, provider);
    const sohmBalance = await sohmContract.balanceOf(address);

    return dispatch(
      fetchSwapSuccess({
        ohm: ethers.utils.formatUnits(ohmBalance, "gwei"),
        sohm: ethers.utils.formatUnits(sohmBalance, "gwei"),
      }),
    );
  };
