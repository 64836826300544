import React from "react";
import styled from "@emotion/styled";
import Countdown from "react-countdown";

// Random component
const Completionist = () => <StyleHeading>Welcome to BSCX!</StyleHeading>;

// Renderer callback with condition
// @ts-ignore
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <>
        <StyleCustomTimer>
          {days > 0 && (
            <>
              <span>
                {days} <span className="text">Days</span>
              </span>
            </>
          )}
          <span>
            {hours} <span className="text">Hours</span>
          </span>
          <span>
            {minutes} <span className="text">Min</span>
          </span>
          <span>
            {seconds} <span className="text">Sec</span>
          </span>
        </StyleCustomTimer>
      </>
    );
  }
};

const StyleCustomTimer = styled.div`
  font-size: 32px;
  color: #0993ec;
  padding: 10px;
  background-color: #161522;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 35px;
  @media (max-width: 767px) {
    font-size: 22px;
    padding: 10px;
  }
  > span {
    padding: 10px;
    margin-right: 20px;
    font-weight: 500;
    > .text {
      font-size: 18px;
    }
  }
  @media (max-width: 767px) {
    > span {
      margin-right: 0;
      > .text {
        font-size: 16px;
      }
    }
  }
`;
const CustomCountDown = date => {
  return (
    <StyleCountDownWrap>
      <Countdown date={date.date} renderer={renderer} />
    </StyleCountDownWrap>
  );
};

const StyleCountDownWrap = styled.div``;
const StyleHeading = styled.h1`
  text-align: center;
  color: #0993ec;
  @media (max-width: 767px) {
    font-size: 22px;
  }
`;

export default CustomCountDown;
