import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { trim } from "../../helpers";
import { calcBondDetails, calculateUserBondDetails } from "../../actions/Bond.actions.js";
import { Grid, Backdrop, Paper, Box, Tab, Tabs, Typography } from "@material-ui/core";
import TabPanel from "../../components/TabPanel";
import BondHeader from "./BondHeader";
import BondRedeemV1 from "./BondRedeemV1";
import BondRedeem from "./BondRedeem";
import BondPurchase from "./BondPurchase";
import { withStyles } from "@material-ui/core/styles";
import "./bond.scss";
import { NETWORK_ID } from "../../constants";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTabs = withStyles({
  root: {
    backgroundColor: "#202230",
    borderRadius: 10,
    height: 50,
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    height: 42,
    zIndex: 1,
    top: 4,
    marginLeft: props => (props.value === 0 ? 4 : -4),
    borderRadius: 10,
  },
})(props => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    zIndex: 2,
    textTransform: "none",
    color: "#fff",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(18),
    "&:focus": {
      opacity: 1,
    },
  },
}))(props => <Tab disableRipple {...props} />);

function Bond({ bond, address, provider }) {
  const dispatch = useDispatch();

  const [slippage, setSlippage] = useState(0.5);
  const [recipientAddress, setRecipientAddress] = useState(address);

  const [view, setView] = useState(0);
  const [quantity, setQuantity] = useState();

  const marketPrice = useSelector(state => {
    return state.bonding[bond] && state.bonding[bond].marketPrice;
  });
  const bondPrice = useSelector(state => {
    return state.bonding[bond] && state.bonding[bond].bondPrice;
  });

  const debtRatio = useSelector(state => {
    return state.bonding[bond] && state.bonding[bond].debtRatio;
  });

  const bondDiscount = useSelector(state => {
    return state.bonding[bond] && state.bonding[bond].bondDiscount;
  });

  const onRecipientAddressChange = e => {
    return setRecipientAddress(e.target.value);
  };

  const onSlippageChange = e => {
    return setSlippage(e.target.value);
  };

  async function loadBondDetails() {
    if (provider) await dispatch(calcBondDetails({ bond, value: quantity, provider, networkID: NETWORK_ID }));

    if (provider && address) {
      await dispatch(calculateUserBondDetails({ address, bond, provider, networkID: NETWORK_ID }));
    }
  }

  useEffect(() => {
    loadBondDetails();
    if (address) setRecipientAddress(address);
  }, [provider, quantity, address]);

  const changeView = (event, newView) => {
    setView(newView);
  };

  return (
    <Grid container id="bond-view">
      <Backdrop open={true}>
        <Grid container spacing={3} className="maxWebAppWidth">
          <Grid item xs={12} md={8}>
            <Paper className="ohm-card ohm-modal">
              <BondHeader
                bond={bond}
                slippage={slippage}
                recipientAddress={recipientAddress}
                onSlippageChange={onSlippageChange}
                onRecipientAddressChange={onRecipientAddressChange}
              />

              {/* <Box p={4}>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <Box>
                    <Typography variant="h3" color="textSecondary">
                      Bond Price
                    </Typography>
                    <Typography variant="h2">
                      <Box fontWeight={400}>
                        {trim(bondPrice, 2)} {bond.indexOf("frax") >= 0 ? "FRAX" : "DAI"}
                      </Box>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h3" color="textSecondary">
                      Market Price
                    </Typography>
                    <Typography variant="h2">
                      <Box mb={2} fontWeight={400}>
                        {trim(marketPrice, 2)} {bond.indexOf("frax") >= 0 ? "FRAX" : "DAI"}
                      </Box>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h3" color="textSecondary" align="right">
                      ROI
                    </Typography>
                    <Typography variant="h2">
                      <Box mb={2} fontWeight={400}>
                        {trim(bondDiscount * 100, 2)}%
                      </Box>
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box pl={4} pr={4}>
                <StyledTabs
                  centered
                  variant="fullWidth"
                  value={view}
                  textColor="primary"
                  indicatorColor="secondary"
                  onChange={changeView}
                  aria-label="stake tabs"
                >
                  <StyledTab label="Bond" {...a11yProps(0)} />
                  <StyledTab label="Redeem" {...a11yProps(0)} />
                </StyledTabs>
              </Box>

              <TabPanel value={view} index={0}>
                <BondPurchase provider={provider} address={address} bond={bond} slippage={slippage} />
              </TabPanel> */}
              <BondRedeem provider={provider} address={address} bond={bond} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper className={`ohm-card`}>
              <Box p={4}>
                <Box mb={1.5}>
                  <Typography variant="h2" color="textPrimary">
                    <Box fontWeight={500}>Market</Box>
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <Typography variant="body2" color="textSecondary">
                    Debt Ratio
                  </Typography>
                  <Typography variant="body2">{trim(debtRatio / 10000000, 2)}%</Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Backdrop>
    </Grid>
  );
}

export default Bond;
