import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import orderBy from "lodash/orderBy";

export const makeBondsArray = (
  asgDaiBondDiscount,
  asgFraxLpBondDiscount,
  daiBondDiscount,
  fraxBondDiscount,
  asgUstBondDiscount,
  ustBondDiscount,
) => {
  return [
    {
      name: "ASG-DAI LP",
      value: "asg_dai_lp",
      discount: Number(asgDaiBondDiscount),
    },
    {
      name: "ASG-FRAX LP",
      value: "asg_frax_lp",
      discount: Number(asgFraxLpBondDiscount),
    },
    {
      name: "DAI",
      value: "dai",
      discount: Number(daiBondDiscount),
    },
    {
      name: "FRAX",
      value: "frax",
      discount: Number(fraxBondDiscount),
    },
    {
      name: "ASG-UST LP",
      value: "asg_ust_lp",
      discount: Number(asgUstBondDiscount),
    },
    {
      name: "UST",
      value: "ust",
      discount: Number(ustBondDiscount),
    },
  ];
};

const BONDS_ARRAY = makeBondsArray();

/**
 * Returns an array of bonds ordered by the most profitable ones first.
 * Each bond object contains its display name, value, and the discount amount.
 *
 * @returns {[{name: string, discount: number, value: string}, {name: string, discount: number, value: string}, {name: string, discount: number, value: string}, {name: string, discount: number, value: string}]}
 */
export default function useBonds() {
  const fraxBondDiscount = useSelector(state => {
    return state.bonding["frax"] && state.bonding["frax"].bondDiscount;
  });

  const daiBondDiscount = useSelector(state => {
    return state.bonding["dai"] && state.bonding["dai"].bondDiscount;
  });

  const asgDaiBondDiscount = useSelector(state => {
    return state.bonding["asg_dai_lp"] && state.bonding["asg_dai_lp"].bondDiscount;
  });

  const ustBondDiscount = useSelector(state => {
    return state.bonding["ust"] && state.bonding["ust"].bondDiscount;
  });

  const asgUstBondDiscount = useSelector(state => {
    return state.bonding["asg_ust_lp"] && state.bonding["asg_ust_lp"].bondDiscount;
  });

  const asgFraxLpBondDiscount = useSelector(state => {
    return state.bonding["asg_frax_lp"] && state.bonding["asg_frax_lp"].bondDiscount;
  });

  const [bonds, setBonds] = useState(BONDS_ARRAY);

  useEffect(() => {
    const bondValues = makeBondsArray(
      asgDaiBondDiscount,
      asgFraxLpBondDiscount,
      daiBondDiscount,
      fraxBondDiscount,
      asgUstBondDiscount,
      ustBondDiscount,
    );
    const mostProfitableBonds = orderBy(bondValues, "discount", "desc");
    setBonds(mostProfitableBonds);
  }, [
    asgDaiBondDiscount,
    asgFraxLpBondDiscount,
    daiBondDiscount,
    fraxBondDiscount,
    ustBondDiscount,
    asgUstBondDiscount,
  ]);

  return bonds;
}
