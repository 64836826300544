import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import orderBy from "lodash/orderBy";

export const makeBondsArray = (asgDaiBondDiscount, asgFraxLpBondDiscount, daiBondDiscount, fraxBondDiscount) => {
  return [
    {
      name: "ASG-DAI LP",
      value: "asg_dai_lp",
      discount: Number(asgDaiBondDiscount),
    },
    {
      name: "ASG-FRAX LP",
      value: "asg_frax_lp",
      discount: Number(asgFraxLpBondDiscount),
    },
    {
      name: "DAI",
      value: "dai",
      discount: Number(daiBondDiscount),
    },
    {
      name: "FRAX",
      value: "frax",
      discount: Number(fraxBondDiscount),
    },
  ];
};

const BONDS_ARRAY = makeBondsArray();

/**
 * Returns an array of bonds ordered by the most profitable ones first.
 * Each bond object contains its display name, value, and the discount amount.
 *
 * @returns {[{name: string, discount: number, value: string}, {name: string, discount: number, value: string}, {name: string, discount: number, value: string}, {name: string, discount: number, value: string}]}
 */
export default function useBonds() {
  const fraxBondDiscount = useSelector(state => {
    return state.bondingFairLaunch["frax"] && state.bondingFairLaunch["frax"].bondDiscount;
  });

  const daiBondDiscount = useSelector(state => {
    return state.bondingFairLaunch["dai"] && state.bondingFairLaunch["dai"].bondDiscount;
  });

  const asgDaiBondDiscount = useSelector(state => {
    return state.bondingFairLaunch["asg_dai_lp"] && state.bondingFairLaunch["asg_dai_lp"].bondDiscount;
  });

  const asgFraxLpBondDiscount = useSelector(state => {
    return state.bondingFairLaunch["asg_frax_lp"] && state.bondingFairLaunch["asg_frax_lp"].bondDiscount;
  });

  const [bonds, setBonds] = useState(BONDS_ARRAY);

  useEffect(() => {
    const bondValues = makeBondsArray(asgDaiBondDiscount, asgFraxLpBondDiscount, daiBondDiscount, fraxBondDiscount);
    const mostProfitableBonds = orderBy(bondValues, "discount", "desc");
    setBonds(mostProfitableBonds);
  }, [asgDaiBondDiscount, asgFraxLpBondDiscount, daiBondDiscount, fraxBondDiscount]);

  return bonds;
}
