import { addresses, EPOCH_INTERVAL, BLOCK_RATE_SECONDS, BONDS } from "../constants";
import { ethers } from "ethers";
import { abi as ierc20Abi } from "../abi/IERC20.json";
import { abi as PairContract } from "../abi/PairContract.json";

import { SvgIcon } from "@material-ui/core";
import { ReactComponent as OhmImg } from "../assets/tokens/asg.svg";
import { ReactComponent as sOhmImg } from "../assets/tokens/sasg.svg";
import { ReactComponent as DaiImg } from "../assets/tokens/dai.svg";
import { ReactComponent as OhmDaiImg } from "../assets/tokens/asg-dai.svg";
import { ReactComponent as FraxImg } from "../assets/tokens/frax.svg";
import { ReactComponent as UstImg } from "../assets/tokens/ust.svg";
import { ReactComponent as OhmFraxImg } from "../assets/tokens/asg-frax.svg";
import { ReactComponent as OhmUstImg } from "../assets/tokens/asg-ust.svg";

import { abi as BondOhmDaiContract } from "../abi/bonds/OhmDaiContract.json";
import { abi as BondOhmFraxContract } from "../abi/bonds/OhmFraxContract.json";
import { abi as BondDaiContract } from "../abi/bonds/DaiContract.json";
import { abi as ReserveOhmDaiContract } from "../abi/reserves/OhmDai.json";
import { abi as ReserveOhmFraxContract } from "../abi/reserves/OhmFrax.json";
import { abi as BondContract } from "../abi/BondContract.json";
import { abi as DaiBondContract } from "../abi/DaiBondContract.json";
import { abi as FraxBondContract } from "../abi/bonds/FraxContract.json";
export { default as Transactor } from "./Transactor";

export function addressForBond({ bond, networkID }) {
  if (bond === BONDS.ohm_dai) {
    return addresses[networkID].BONDS.OHM_DAI;
  }
  if (bond === BONDS.dai) {
    return addresses[networkID].BONDS.DAI;
  }
  if (bond === BONDS.ohm_frax) {
    return addresses[networkID].BONDS.OHM_FRAX;
  }
  if (bond === BONDS.frax) {
    return addresses[networkID].BONDS.FRAX;
  }
  if (bond === BONDS.ust) {
    return addresses[networkID].BONDS.UST;
  }
  if (bond === BONDS.ohm_ust) {
    return addresses[networkID].BONDS.OHM_UST;
  }
}

export function addressForBondFairLaunch({ bond, networkID }) {
  if (bond === BONDS.ohm_dai) {
    return addresses[networkID].FAIR_BONDS.OHM_DAI;
  }
  if (bond === BONDS.dai) {
    return addresses[networkID].FAIR_BONDS.DAI;
  }
  if (bond === BONDS.ohm_frax) {
    return addresses[networkID].FAIR_BONDS.OHM_FRAX;
  }
  if (bond === BONDS.frax) {
    return addresses[networkID].FAIR_BONDS.FRAX;
  }
}

export function addressForAsset({ bond, networkID }) {
  if (bond === BONDS.ohm_dai) {
    return addresses[networkID].RESERVES.OHM_DAI;
  }
  if (bond === BONDS.dai) {
    return addresses[networkID].RESERVES.DAI;
  }
  if (bond === BONDS.ohm_frax) {
    return addresses[networkID].RESERVES.OHM_FRAX;
  }
  if (bond === BONDS.frax) {
    return addresses[networkID].RESERVES.FRAX;
  }
  if (bond === BONDS.ust) {
    return addresses[networkID].RESERVES.UST;
  }
  if (bond === BONDS.ohm_ust) {
    return addresses[networkID].RESERVES.OHM_UST;
  }
}

export function isBondLP(bond) {
  return bond.indexOf("_lp") >= 0;
}

export function lpURL(bond) {
  if (bond === BONDS.ohm_dai)
    return "https://app.sushi.com/add/0x0DC5189Ec8CDe5732a01F0F592e927B304370551/0x6b175474e89094c44da98b954eedeac495271d0f";
  if (bond === BONDS.ohm_frax)
    return "https://app.sushi.com/add/0x0DC5189Ec8CDe5732a01F0F592e927B304370551/0x853d955acef822db058eb8505911ed77f175b99e";
  if (bond === BONDS.ohm_ust)
    return "https://app.sushi.com/add/0x0DC5189Ec8CDe5732a01F0F592e927B304370551/0xa47c8bf37f92aBed4A126BDA807A7b7498661acD";
}

export function bondName(bond) {
  if (bond === BONDS.dai) return "DAI Bond";
  if (bond === BONDS.ohm_dai) return "ASG-DAI SLP Bond";
  if (bond === BONDS.ohm_frax) return "ASG-FRAX LP Bond";
  if (bond === BONDS.ust) return "wUST Bond";
  if (bond === BONDS.ohm_ust) return "ASG-wUST Bond";
  if (bond === BONDS.frax) return "FRAX Bond";
}

export function contractForBond({ bond, networkID, provider }) {
  const address = addressForBond({ bond, networkID });

  if (bond === BONDS.ohm_dai) {
    return new ethers.Contract(addresses[networkID].BONDS.OHM_DAI, BondOhmDaiContract, provider);
  }
  if (bond === BONDS.dai) {
    return new ethers.Contract(addresses[networkID].BONDS.DAI, BondDaiContract, provider);
  }
  if (bond === BONDS.ohm_dai_v1) {
    return new ethers.Contract(addresses[networkID].BOND_ADDRESS, BondContract, provider);
  }
  if (bond === BONDS.dai_v1) {
    return new ethers.Contract(addresses[networkID].DAI_BOND_ADDRESS, DaiBondContract, provider);
  }
  if (bond === BONDS.ohm_frax) {
    return new ethers.Contract(addresses[networkID].BONDS.OHM_FRAX, BondOhmFraxContract, provider);
  }
  if (bond === BONDS.ohm_frax_v1) {
    return new ethers.Contract(addresses[networkID].BONDS.OHM_FRAX_BOND_ADDRESS, BondOhmFraxContract, provider);
  }
  if (bond === BONDS.frax) {
    return new ethers.Contract(address, FraxBondContract, provider);
  }
  if (bond === BONDS.ohm_ust) {
    return new ethers.Contract(addresses[networkID].BONDS.OHM_UST, BondOhmFraxContract, provider);
  }
  if (bond === BONDS.ust) {
    return new ethers.Contract(addresses[networkID].BONDS.UST, FraxBondContract, provider);
  }
}

export function contractForBondFairLaunch({ bond, networkID, provider }) {
  const address = addressForBondFairLaunch({ bond, networkID });

  if (bond === BONDS.ohm_dai) {
    return new ethers.Contract(addresses[networkID].FAIR_BONDS.OHM_DAI, BondOhmDaiContract, provider);
  }
  if (bond === BONDS.dai) {
    return new ethers.Contract(addresses[networkID].FAIR_BONDS.DAI, BondDaiContract, provider);
  }
  if (bond === BONDS.ohm_dai_v1) {
    return new ethers.Contract(addresses[networkID].BOND_ADDRESS, BondContract, provider);
  }
  if (bond === BONDS.dai_v1) {
    return new ethers.Contract(addresses[networkID].DAI_BOND_ADDRESS, DaiBondContract, provider);
  }
  if (bond === BONDS.ohm_frax) {
    return new ethers.Contract(addresses[networkID].FAIR_BONDS.OHM_FRAX, BondOhmFraxContract, provider);
  }
  if (bond === BONDS.ohm_frax_v1) {
    return new ethers.Contract(addresses[networkID].FAIR_BONDS.OHM_FRAX_BOND_ADDRESS, BondOhmFraxContract, provider);
  }
  if (bond === BONDS.frax) {
    return new ethers.Contract(address, FraxBondContract, provider);
  }
}

export function contractForReserve({ bond, networkID, provider }) {
  const address = addressForAsset({ bond, networkID });

  if (bond === BONDS.ohm_dai || bond === BONDS.ohm_dai_v1) {
    return new ethers.Contract(addresses[networkID].RESERVES.OHM_DAI, ReserveOhmDaiContract, provider);
  }
  if (bond === BONDS.dai || bond === BONDS.dai_v1) {
    return new ethers.Contract(addresses[networkID].RESERVES.DAI, ierc20Abi, provider);
  }
  if (bond === BONDS.ohm_frax || bond === BONDS.ohm_frax_v1) {
    return new ethers.Contract(addresses[networkID].RESERVES.OHM_FRAX, ReserveOhmFraxContract, provider);
  }
  if (bond === BONDS.frax) {
    return new ethers.Contract(address, ierc20Abi, provider);
  }
  if (bond === BONDS.ust) {
    return new ethers.Contract(addresses[networkID].RESERVES.UST, ierc20Abi, provider);
  }
  if (bond === BONDS.ohm_ust) {
    return new ethers.Contract(addresses[networkID].RESERVES.OHM_UST, ierc20Abi, provider);
  }
}

export async function getMarketPrice({ networkID, provider }) {
  const pairContract = new ethers.Contract(addresses[networkID].LP_ADDRESS, PairContract, provider);
  const reserves = await pairContract.getReserves();
  const marketPrice = reserves[1] / reserves[0];

  // commit('set', { marketPrice: marketPrice / Math.pow(10, 9) });
  return marketPrice;
}

export function shorten(str) {
  if (str.length < 10) return str;
  return `${str.slice(0, 6)}...${str.slice(str.length - 4)}`;
}

export function trim(number, precision) {
  if (number == undefined) {
    number = 0;
  }
  const array = number.toString().split(".");
  if (array.length === 1) return number.toString();

  array.push(array.pop().substring(0, precision));
  const trimmedNumber = array.join(".");
  return trimmedNumber;
}

export function getRebaseBlock(currentBlock) {
  return currentBlock + EPOCH_INTERVAL - (currentBlock % EPOCH_INTERVAL);
}

export function secondsUntilBlock(startBlock, endBlock) {
  if (startBlock % EPOCH_INTERVAL === 0) {
    return 0;
  }

  const blocksAway = endBlock - startBlock;
  const secondsAway = blocksAway * BLOCK_RATE_SECONDS;

  return secondsAway;
}

export function prettyVestingPeriod(currentBlock, vestingBlock) {
  if (vestingBlock === 0) {
    return "";
  }

  const seconds = secondsUntilBlock(currentBlock, vestingBlock);
  if (seconds < 0) {
    return "Fully Vested";
  }
  return prettifySeconds(seconds);
}

export function prettifySeconds(seconds, resolution) {
  if (seconds !== 0 && !seconds) {
    return "";
  }

  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);

  if (resolution === "day") {
    return d + (d == 1 ? " day" : " days");
  }

  const dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
  const hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hrs, ") : "";
  const mDisplay = m > 0 ? m + (m == 1 ? " min" : " mins") : "";

  return dDisplay + hDisplay + mDisplay;
}

function getSohmTokenImage() {
  return <SvgIcon component={OhmImg} viewBox="0 0 32 32" />;
}

function getOhmTokenImage() {
  return <SvgIcon component={sOhmImg} viewBox="0 0 32 32" />;
}

function getDaiTokenImage() {
  return <SvgIcon component={DaiImg} viewBox="0 0 32 32" style={{ height: "32px", width: "32px" }} />;
}

function getFraxTokenImage() {
  return <SvgIcon component={FraxImg} viewBox="0 0 32 32" style={{ height: "32px", width: "32px" }} />;
}

function getUstTokenImage() {
  return <SvgIcon component={UstImg} viewBox="0 0 40 40" style={{ height: "32px", width: "32px" }} />;
}

export function getTokenImage(name) {
  if (name === "ohm") return getOhmTokenImage();
  if (name === "sohm") return getSohmTokenImage();
  if (name === "dai") return getDaiTokenImage();
  if (name === "frax") return getFraxTokenImage();
  if (name === "ust") return getUstTokenImage();
}

export function getPairImage(name) {
  if (name.indexOf("dai") >= 0)
    return <SvgIcon component={OhmDaiImg} viewBox="0 0 76 40" style={{ height: "32px", width: "64px" }} />;
  if (name.indexOf("frax") >= 0)
    return <SvgIcon component={OhmFraxImg} viewBox="0 0 76 40" style={{ height: "32px", width: "64px" }} />;
  if (name.indexOf("ust") >= 0)
    return <SvgIcon component={OhmUstImg} viewBox="0 0 76 40" style={{ height: "32px", width: "64px" }} />;
}

export function priceUnits(bond) {
  if (bond.indexOf("frax") >= 0) return <img src={`${getFraxTokenImage()}`} width="15px" height="15px" />;
  else return <img src={`${getDaiTokenImage()}`} width="15px" height="15px" />;
}
