import { useSelector } from "react-redux";
import {
  Paper,
  Grid,
  Typography,
  Box,
  Slide,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Zoom,
  CardHeader,
  CardContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { BondTableData, BondDataCard } from "./BondRow";
import { BONDS } from "../../constants";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { trim } from "../../helpers";
import useBonds from "../../hooks/BondsFair";
import "./choosebond.scss";

const useStyles = makeStyles({
  table: {
    borderCollapse: "separate",
    borderSpacing: "0px 8px",
  },
});

function ChooseBond() {
  const classes = useStyles();
  const bonds = useBonds();
  const isSmallScreen = useMediaQuery("(max-width: 733px)"); // change to breakpoint query
  const isVerySmallScreen = useMediaQuery("(max-width: 420px)");

  const marketPrice = useSelector(state => {
    return state.app.marketPrice;
  });

  const treasuryBalance = useSelector(state => {
    return state.app.treasuryBalance;
  });

  return (
    <div id="choose-bond-view">
      <Card className={"ohm-card"}>
        <Box px={4} py={3} className="FairbondHeader">
          <Typography variant="h1" color="textPrimary">
            <Box fontWeight={500}>Redeem Fair Launch Bond</Box>
          </Typography>
        </Box>
        <Box pt={4} px={4} pb={2} display="flex" flexDirection="row">
          <Box>
            <Typography variant="h3" color="textSecondary">
              Treasury Balance
            </Typography>
            <Typography variant="h2">
              <Box fontWeight={400}>
                {treasuryBalance &&
                  new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  }).format(treasuryBalance)}
              </Box>
            </Typography>
          </Box>
          {/* PRICE DOESNT MATTER
          <Box ml={7}>
            <Typography variant="h3" color="textSecondary">
              ASG Price
            </Typography>
            <Typography variant="h2">
              <Box fontWeight={400}>{trim(marketPrice, 2)}</Box>
            </Typography>
          </Box>
          */}
        </Box>
        {!isSmallScreen && (
          <Box px={4} pb={4}>
            <TableContainer>
              <Table className={classes.table} aria-label="Available bonds">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" className="textTertiary">
                      Bond
                    </TableCell>
                    <TableCell align="right" className="textTertiary">
                      Purchased
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bonds.map(bond => (
                    <BondTableData key={bond.value} bond={bond.value} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Card>

      {isSmallScreen && (
        <Box className="ohm-card-container">
          <Grid container item spacing={2}>
            {/* { Object.keys(BONDS).map(bond => ( */}
            {[BONDS.ohm_dai, BONDS.dai, BONDS.ohm_frax, BONDS.frax].map(bond => (
              <Grid item xs={12} key={`bond-${bond}`}>
                <BondDataCard key={bond} bond={bond} />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </div>
  );
}

export default ChooseBond;
