import { useState } from "react";
import { addresses, NETWORK_ID } from "../../constants";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { Link, SvgIcon, Popper, Button, Paper, Typography, Divider, Box } from "@material-ui/core";
import { ReactComponent as ArrowUpIcon } from "../../assets/icons/v1.2/arrow-up.svg";
import { ReactComponent as ArrowUp } from "../../assets/icons/arrow-up.svg";
import "./ohmmenu.scss";

function OhmMenu() {
  const [anchorEl, setAnchorEl] = useState(null);

  const networkID = useSelector(state => {
    return (state.app && state.app.networkID) || NETWORK_ID;
  });

  const OHM_ADDRESS = addresses[networkID].OHM_ADDRESS;

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "ohm-popper" : undefined;

  return (
    <>
      <Button
        className="menuButton"
        variant="contained"
        color="secondary"
        title="ASG"
        onClick={handleClick}
        aria-describedby={id}
      >
        Buy ASG
      </Button>
      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
        <Paper className="ASGMenu" elevation={1}>
          <Box pt={2} pb={2} pl={2} pr={2}>
            <Link
              href={`https://app.sushi.com/swap?inputCurrency=${addresses[networkID].RESERVES.DAI}&outputCurrency=${OHM_ADDRESS}`}
              target="_blank"
              rel="noreferrer"
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                <Typography variant="body1">
                  <Box mr={0.5}>Buy on Sushiswap</Box>
                </Typography>
                <SvgIcon component={ArrowUp} viewBox="0 0 16 16" />
              </Box>
            </Link>
          </Box>
        </Paper>
      </Popper>
    </>
  );
}

export default OhmMenu;
