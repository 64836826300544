import { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Box,
  Paper,
  Typography,
  FormControl,
  InputAdornment,
  InputLabel,
  InputBase,
  OutlinedInput,
  Button,
  SvgIcon,
  Tab,
  Tabs,
  TableHead,
  TableCell,
  TableBody,
  Table,
  TableRow,
  TableContainer,
  Link,
  Zoom,
  Backdrop,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import NewReleases from "@material-ui/icons/NewReleases";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RebaseTimer from "../../components/RebaseTimer/RebaseTimer";
import TabPanel from "../../components/TabPanel";
import Countdown from "../../components/Countdown";
import { trim, getTokenImage } from "../../helpers";
import { ReactComponent as OhmImg } from "../../assets/tokens/asg-large.svg";
import { ReactComponent as sOhmImg } from "../../assets/tokens/sasg.svg";
import { changeStake, changeApproval } from "../../actions/Stake.actions";
import { getFraxData } from "../../actions/App.actions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ReactComponent as ArrowUp } from "../../assets/icons/arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down.svg";
import { ReactComponent as Export } from "../../assets/icons/export.svg";
import { NETWORK_ID } from "../../constants";

import "./stake.scss";
import { NavLink } from "react-router-dom";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const sohmImg = getTokenImage("sohm");
const ohmImg = getTokenImage("ohm");
const fraxImg = getTokenImage("frax");

const StyledTabs = withStyles({
  root: {
    backgroundColor: "#202230",
    borderRadius: 10,
    height: 50,
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    height: 42,
    zIndex: 1,
    top: 4,
    marginLeft: props => (props.value === 0 ? 4 : -4),
    borderRadius: 10,
  },
})(props => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    zIndex: 2,
    textTransform: "none",
    color: "#fff",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(18),
    "&:focus": {
      opacity: 1,
    },
  },
}))(props => <Tab disableRipple {...props} />);

function Stake({ provider, address, web3Modal, loadWeb3Modal }) {
  const dispatch = useDispatch();

  const [view, setView] = useState(1);
  const [quantity, setQuantity] = useState();

  const isSmallScreen = useMediaQuery("(max-width: 705px)");
  const isMobileScreen = useMediaQuery("(max-width: 513px)");

  const currentIndex = useSelector(state => {
    return state.app.currentIndex;
  });
  const fraxData = useSelector(state => {
    return state.fraxData;
  });
  const fiveDayRate = useSelector(state => {
    return state.app.fiveDayRate;
  });
  const ohmBalance = useSelector(state => {
    return state.app.balances && state.app.balances.ohm;
  });
  const oldSohmBalance = useSelector(state => {
    return state.app.balances && state.app.balances.oldsohm;
  });
  const sohmBalance = useSelector(state => {
    return state.app.balances && state.app.balances.sohm;
  });
  const stakeAllowance = useSelector(state => {
    return state.app.staking && state.app.staking.ohmStake;
  });
  const unstakeAllowance = useSelector(state => {
    return state.app.staking && state.app.staking.ohmUnstake;
  });
  const stakingRebase = useSelector(state => {
    return state.app.stakingRebase;
  });
  const stakingAPY = useSelector(state => {
    return state.app.stakingAPY;
  });
  const stakingTVL = useSelector(state => {
    return state.app.stakingTVL;
  });

  const setMax = () => {
    if (view === 0) {
      setQuantity(ohmBalance);
    } else {
      setQuantity(sohmBalance);
    }
  };

  const onSeekApproval = async token => {
    await dispatch(changeApproval({ address, token, provider, networkID: NETWORK_ID }));
  };

  const onChangeStake = async action => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(quantity) || quantity === 0 || quantity === "") {
      // eslint-disable-next-line no-alert
      alert("Please enter a value!");
    } else {
      await dispatch(changeStake({ address, action, value: quantity.toString(), provider, networkID: NETWORK_ID }));
    }
  };

  const hasAllowance = useCallback(
    token => {
      if (token === "ohm") return stakeAllowance > 0;
      if (token === "sohm") return unstakeAllowance > 0;
      return 0;
    },
    [stakeAllowance],
  );

  const loadFraxData = async () => {
    dispatch(getFraxData());
  };

  useEffect(() => {
    loadFraxData();
  }, []);

  let modalButton = [];

  if (web3Modal) {
    modalButton.push(
      <Button
        variant="contained"
        color="primary"
        className="connect-button uniform-button"
        onClick={loadWeb3Modal}
        key={2}
      >
        Connect to a wallet
      </Button>,
    );
  }

  const changeView = (event, newView) => {
    setView(newView);
  };

  const trimmedSOHMBalance = trim(sohmBalance, 4);
  const stakingRebasePercentage = trim(stakingRebase * 100, 4);
  const trimmedStakingAPY = trim(stakingAPY * 100, 1);
  const nextRewardValue = trim((stakingRebasePercentage / 100) * trimmedSOHMBalance, 4);
  return (
    <div id="stake-view">
      <Grid container spacing={3} className="maxWebAppWidth">
        <Grid item xs={12} md={8}>
          <Box mb={2.5}>
            <Paper className={`ohm-card apy-card`}>
              <Box>
                <Typography variant="h1" color="textPrimary">
                  <Box fontWeight={500}>APY</Box>
                </Typography>
                <Box mt={0.5}>
                  <Link
                    className="apy-link"
                    href={"https://docs.asgarddao.finance/basics/faq#how-is-the-apy-calculated"}
                    target="_blank"
                  >
                    <Typography variant="body2" color="textSecondary">
                      <Box mr={0.5}>How is sASG APY calculated?</Box>
                    </Typography>
                  </Link>
                </Box>
              </Box>

              <Typography variant="h1" color="textPrimary">
                <Box fontWeight={500}>{stakingAPY && new Intl.NumberFormat("en-US").format(trimmedStakingAPY)}%</Box>
              </Typography>
            </Paper>
          </Box>
          <Paper className="ohm-card action-pannel">
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Typography variant="h3" color="textSecondary">
                  TVL
                </Typography>
                <Typography variant="h2">
                  <Box fontWeight={400}>
                    {stakingTVL &&
                      new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      }).format(stakingTVL)}
                  </Box>
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant="h3" color="textSecondary">
                  Current Index
                </Typography>
                <Typography variant="h2">
                  <Box mb={2} fontWeight={400}>
                    {currentIndex && trim(currentIndex, 1)} ASG
                  </Box>
                </Typography>
              </Grid>
              <Grid item md={12}>
                {!address ? (
                  <div className="stake-wallet-notification">
                    <div className="wallet-menu" id="wallet-menu">
                      {modalButton}
                    </div>
                    <Typography variant="body1" color="textSecondary">
                      <Box mt={2}>Connect your wallet to stake ASG</Box>
                    </Typography>
                  </div>
                ) : (
                  <>
                    <Box className="stake-action-area">
                      {/* <StyledTabs
                        centered
                        variant="fullWidth"
                        value={view}
                        textColor="primary"
                        indicatorColor="secondary"
                        onChange={changeView}
                        aria-label="stake tabs"
                      >
                        <StyledTab label="Stake" {...a11yProps(0)} />
                        <StyledTab label="Unstake" {...a11yProps(0)} />
                      </StyledTabs> */}

                      <Box mt={3} alignItems="center">
                        <Box>
                          <Typography variant="h1">
                            <Box mb={2} fontWeight={500}>
                              {view === 0 ? "Stake (6, 6)" : "Unstake"}
                            </Box>
                          </Typography>
                        </Box>
                        <Box
                          mb={2}
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          className="textTertiary"
                        >
                          <Box display="flex" flexDirection="row" alignItems="center">
                            <SvgIcon
                              component={view === 0 ? ArrowDown : ArrowUp}
                              viewBox="0 0 16 16"
                              htmlColor="#7F7F7F"
                            />
                            <Typography variant="body1">
                              <Box ml={0.5}>{view === 0 ? "Add ASG" : "Receive ASG"}</Box>
                            </Typography>
                          </Box>
                          <Typography variant="body1">
                            {view === 0 ? `Balance ${trim(ohmBalance)} ASG` : `Balance ${trim(sohmBalance)} sASG`}
                          </Typography>
                        </Box>
                        <FormControl className="formInput" variant="outlined" color="primary" fullWidth="true">
                          <InputLabel htmlFor="amount-input"></InputLabel>
                          <InputBase
                            id="amount-input"
                            type="number"
                            placeholder="Enter an amount"
                            className="stake-input"
                            autoFocus="true"
                            value={quantity}
                            onChange={e => setQuantity(e.target.value)}
                            labelwidth={0}
                            endAdornment={
                              <InputAdornment position="end">
                                <Button className="inputMaxButton" variant="text" onClick={setMax}>
                                  Max
                                </Button>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                        <Box mt={2}>
                          {/* <TabPanel value={view} index={0} className="stake-tab-panel">
                            {address && hasAllowance("ohm") ? (
                              <Button
                                className="stake-button"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  onChangeStake("stake");
                                }}
                              >
                                Stake ASG
                              </Button>
                            ) : (
                              <Button
                                className="approve-button"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  onSeekApproval("ohm");
                                }}
                              >
                                Approve
                              </Button>
                            )}
                          </TabPanel> */}

                          {/* <TabPanel value={view} index={1} className="stake-tab-panel"> */}
                          {address && hasAllowance("sohm") ? (
                            <Button
                              className="unstake-button"
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                onChangeStake("unstake");
                              }}
                            >
                              Unstake ASG
                            </Button>
                          ) : (
                            <Button
                              className="approve-button"
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                onSeekApproval("sohm");
                              }}
                            >
                              Approve
                            </Button>
                          )}
                          {/* </TabPanel> */}
                        </Box>
                      </Box>

                      <div className="help-text">
                        {address && ((!hasAllowance("ohm") && view === 0) || (!hasAllowance("sohm") && view === 1)) && (
                          <Box mt={2}>
                            <Typography variant="body2" color="textSecondary" align="center">
                              The "Approve" transaction is only needed when unstaking for the first time; subsequent
                              unstaking only requires you to perform the "Unstake" transaction.
                            </Typography>
                          </Box>
                        )}
                      </div>
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className={`ohm-card`}>
            <Box mb={1.5}>
              <Typography variant="h2" color="textPrimary">
                <Box fontWeight={500}>Balance</Box>
              </Typography>
            </Box>
            <Box className="balance">
              <SvgIcon component={sOhmImg} viewBox="0 0 64 64" style={{ height: "64px", width: "64px" }} />
              <Box ml={2} className="balance-labels">
                <Typography variant="h3" color="textPrimary">
                  {trimmedSOHMBalance}
                </Typography>
                <Typography variant="h3" color="textSecondary">
                  sASG
                </Typography>
              </Box>
            </Box>

            <div className={`stake-user-data`}>
              <Box mb={1} display="flex" flexDirection="row" justifyContent="space-between">
                <Typography variant="body2" color="textSecondary">
                  Next Reward
                </Typography>
                <Typography variant="body2">{nextRewardValue} sASG</Typography>
              </Box>

              <Box mb={1} display="flex" flexDirection="row" justifyContent="space-between">
                <Typography variant="body2" color="textSecondary">
                  Next Reward Yield
                </Typography>
                <Typography variant="body2">{stakingRebasePercentage}%</Typography>
              </Box>

              <Box mb={1} display="flex" flexDirection="row" justifyContent="space-between">
                <Typography variant="body2" color="textSecondary">
                  ROI (5-Day Rate)
                </Typography>
                <Typography variant="body2">{trim(fiveDayRate * 100, 4)}%</Typography>
              </Box>

              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Typography variant="body2" color="textSecondary">
                  Next Rebase
                </Typography>
                <RebaseTimer />
              </Box>
            </div>
            <Box mt={7} mb={1.5}>
              <Typography variant="h2" color="textPrimary">
                <Box fontWeight={500}>Unstaked</Box>
              </Typography>
            </Box>
            <Box className="balance">
              <SvgIcon component={OhmImg} viewBox="0 0 64 64" style={{ height: "64px", width: "64px" }} />
              <Box ml={2} className="balance-labels">
                <Typography variant="h3" color="textPrimary">
                  {trim(ohmBalance)}
                </Typography>
                <Typography variant="h3" color="textSecondary">
                  ASG
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}></Grid>
      </Grid>
    </div>
  );
}

export default Stake;
