import { useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import Social from "../Social";
import externalUrls from "./externalUrls";
import { ReactComponent as StakeIcon } from "../../assets/icons/v1.2/stake.svg";
import { ReactComponent as RedistributionIcon } from "../../assets/icons/v1.2/redistribution.svg";
import { ReactComponent as BondIcon } from "../../assets/icons/v1.2/bond.svg";
import { ReactComponent as RedeemIcon } from "../../assets/icons/v1.2/redeem.svg";
import { ReactComponent as DashboardIcon } from "../../assets/icons/v1.2/dashboard.svg";
import { ReactComponent as OlympusIcon } from "../../assets/icons/v1.2/asgard.svg";
import { ReactComponent as AsgardSmall } from "../../assets/icons/asgard-small-logo.svg";
import { trim, shorten } from "../../helpers";
import useBonds from "../../hooks/Bonds";
import { Paper, Link, Box, Typography, LinearProgress, SvgIcon } from "@material-ui/core";
import "./sidebar.scss";

function NavContent({ address }) {
  const [isActive] = useState();
  const bonds = useBonds();

  const checkPage = useCallback((match, location, page) => {
    const currentPath = location.pathname.replace("/", "");
    if (currentPath.indexOf("dashboard") >= 0 && page === "dashboard") {
      return true;
    }
    if (currentPath.indexOf("stake") >= 0 && page === "stake") {
      return true;
    }
    if ((currentPath.indexOf("bonds") >= 0 || currentPath.indexOf("choose_bond") >= 0) && page === "bonds") {
      return true;
    }
    if (
      (currentPath.indexOf("bonds-fair-launch") >= 0 || currentPath.indexOf("choose_bond") >= 0) &&
      page === "bonds-fair-launch"
    ) {
      return true;
    }
    return false;
  }, []);

  return (
    <Paper className="dapp-sidebar">
      <div className="dapp-menu-top">
        <Box
          ml={4}
          mr={4}
          mt={2.5}
          mb={4}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Link href="https://asgarddao.finance" target="_blank">
            <SvgIcon
              color="primary"
              component={AsgardSmall}
              viewBox="0 0 37 32"
              style={{ minWdth: "37px", minHeight: "32px", width: "37px" }}
            />
          </Link>
          {address && (
            <div className="wallet-link">
              <Link href={`https://etherscan.io/address/${address}`} target="_blank">
                <Box className="walletBox">{shorten(address)}</Box>
              </Link>
            </div>
          )}
        </Box>

        <div className="dapp-menu-links">
          <div className="dapp-nav" id="navbarNav">
            <Link
              component={NavLink}
              id="dash-nav"
              to="/dashboard"
              isActive={(match, location) => {
                return checkPage(match, location, "dashboard");
              }}
              className={`button-dapp-menu ${isActive ? "active" : ""}`}
            >
              <Typography variant="body1">
                <SvgIcon color="primary" component={DashboardIcon} />
                Dashboard
              </Typography>
            </Link>

            <Link
              component={NavLink}
              id="redistribution-nav"
              to="/redistribution"
              isActive={(match, location) => {
                return checkPage(match, location, "redistribution");
              }}
              className={`button-dapp-menu ${isActive ? "active" : ""}`}
            >
              <Typography variant="body1">
                <SvgIcon component={RedistributionIcon} />
                Redistribution
              </Typography>
            </Link>

            <Link
              component={NavLink}
              id="stake-nav"
              to="/"
              isActive={(match, location) => {
                return checkPage(match, location, "stake");
              }}
              className={`button-dapp-menu ${isActive ? "active" : ""}`}
            >
              <Typography variant="body1">
                <SvgIcon component={StakeIcon} />
                Unstake
              </Typography>
            </Link>

            <Link
              component={NavLink}
              id="bond-nav"
              to="/bonds"
              isActive={(match, location) => {
                return checkPage(match, location, "bonds");
              }}
              className={`button-dapp-menu ${isActive ? "active" : ""}`}
            >
              <Typography variant="body1">
                <SvgIcon color="primary" component={BondIcon} />
                Redeem Bonds
              </Typography>
            </Link>

            <div className="dapp-menu-data discounts">
              <div className="bond-discounts">
                <Typography variant="body2" color="textSecondary">
                  Bonds
                </Typography>
                {bonds.map((bond, i) => (
                  <Link component={NavLink} to={`/bonds/${bond.value}`} key={i} className={"bond"}>
                    {!bond.discount ? (
                      <>
                        <LinearProgress />
                      </>
                    ) : (
                      <Typography variant="body2">
                        {bond.name}
                        {/* <span className="bond-pair-roi">{trim(bond.discount * 100, 2)}%</span> */}
                      </Typography>
                    )}
                  </Link>
                ))}
              </div>
            </div>
            {/* 
            <Link
              component={NavLink}
              id="bond-nav"
              to="/bonds-fair-launch"
              isActive={(match, location) => {
                return checkPage(match, location, "bonds-fair-launch");
              }}
              className={`button-dapp-menu ${isActive ? "active" : ""}`}
            >
              <Typography variant="body1">
                <SvgIcon color="primary" component={RedeemIcon} />
                Redeem Fair Launch Bond
              </Typography>
            </Link> */}
          </div>
        </div>
      </div>
      <div className="dapp-menu-data bottom">
        <div className="dapp-menu-external-links">
          {Object.keys(externalUrls).map((link, i) => {
            return (
              <Link key={i} href={`${externalUrls[link].url}`} target="_blank">
                <Typography variant="body1">{externalUrls[link].icon}</Typography>
                <Typography variant="body1">{externalUrls[link].title}</Typography>
              </Link>
            );
          })}
        </div>
      </div>

      <div className="dapp-menu-social">
        <Social />
      </div>
    </Paper>
  );
}

export default NavContent;
