import { combineReducers } from "redux";
import { app, bonding, bondingFairLaunch, fraxData } from "./reducers";

const rootReducer = combineReducers({
  app,
  bonding,
  bondingFairLaunch,
  fraxData,
});

export default rootReducer;
