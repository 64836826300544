import { useSelector, useDispatch } from "react-redux";
import { Button, Typography, Box } from "@material-ui/core";
import { redeemBond } from "../../actions/Bond.actions.js";
import { trim, secondsUntilBlock, prettifySeconds, prettyVestingPeriod } from "../../helpers";
import Countdown from "react-countdown";
import { NETWORK_ID } from "../../constants";

function BondRedeem({ provider, address, bond }) {
  const dispatch = useDispatch();

  const currentBlock = useSelector(state => {
    return state.app.currentBlock;
  });

  const bondMaturationBlock = useSelector(state => {
    return state.bonding[bond] && state.bonding[bond].bondMaturationBlock;
  });

  const vestingTerm = useSelector(state => {
    return state.bonding[bond] && state.bonding[bond].vestingBlock;
  });

  const interestDue = useSelector(state => {
    return state.bonding[bond] && state.bonding[bond].interestDue;
  });

  const pendingPayout = useSelector(state => {
    return state.bonding[bond] && state.bonding[bond].pendingPayout;
  });

  async function onRedeem({ autostake }) {
    await dispatch(redeemBond({ address, bond, networkID: NETWORK_ID, provider, autostake }));
  }

  const vestingTime = () => {
    return prettyVestingPeriod(currentBlock, bondMaturationBlock);
  };

  const vestingPeriod = () => {
    const vestingBlock = parseInt(currentBlock) + parseInt(vestingTerm);
    const seconds = secondsUntilBlock(currentBlock, vestingBlock);
    return prettifySeconds(seconds, "day");
  };

  const bondDiscount = useSelector(state => {
    return state.bonding[bond] && state.bonding[bond].bondDiscount;
  });

  const debtRatio = useSelector(state => {
    return state.bonding[bond] && state.bonding[bond].debtRatio;
  });

  return (
    <>
      <Box pl={4} pr={4} pb={4} className="DELETE-ME">
        <Box mt={3} alignItems="center">
          <Box>
            <Typography variant="h1">
              <Box mb={2} fontWeight={500}>
                Redeem
              </Box>
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box p={2} className="pendingRewards">
            <Box mb={1} display="flex" flexDirection="row" justifyContent="space-between">
              <Typography variant="body1" color="textSecondary">
                Pending Rewards
              </Typography>
              <Typography id="bond-value-id" variant="body1">
                {trim(interestDue, 4)} ASG
              </Typography>
            </Box>
            <Box mb={1} display="flex" flexDirection="row" justifyContent="space-between">
              <Typography variant="body1" color="textSecondary">
                Claimable Rewards
              </Typography>
              <Typography id="bond-value-id" variant="body1">
                <Box className="pendingRewardsNumber">{trim(pendingPayout, 4)} ASG</Box>
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Typography variant="body1" color="textSecondary">
                Time Until Fully Vested
              </Typography>
              <Typography id="bond-value-id" variant="body1">
                {vestingTime()}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box mt={3}>
          <Button
            variant="contained"
            color="primary"
            id="bond-claim-btn"
            className="claim-button"
            fullWidth
            onClick={() => {
              onRedeem({ autostake: false });
            }}
          >
            Claim
          </Button>
        </Box>
        <Box mt={2}>
          <Button
            disabled
            variant="contained"
            color="primary"
            id="bond-claim-autostake-btn"
            className="claim-stake-button"
            fullWidth
            onClick={() => {
              onRedeem({ autostake: true });
            }}
          >
            {`Claim and Stake`}
          </Button>
        </Box>
        <Box mt={2}>
          <Typography variant="body2" color="textSecondary" align="center">
            "Claim and Stake" will automatically stake your ASG.
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default BondRedeem;
