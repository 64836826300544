// export const INFURA_ID = "d9836dbf00c2440d862ab571b462e4a3"; // Girth's fallback
// 5e3c is owned by zeus@oly, 31e6 is owned by unbanksy. Use Girth's fallback if we run out of requests
require("dotenv").config();
const data = require("./addressList.json");
export const INFURA_ID = "a688cedfb5b348d88011cbe538ca063f";
export const THE_GRAPH_URL = "http://127.0.0.1:8001/subgraphs/name/olympus";
export const EPOCH_INTERVAL = 2200;

export const NETWORK_ID = 1;

export const FALLBACK_INFURA_IDS = [
  "5e3c4a19b5f64c99bf8cd8089c92b44d", // this is main dev node
  "d9836dbf00c2440d862ab571b462e4a3", // this is current prod node
  "31e6d348d16b4a4dacde5f8a47da1971", // this is primary fallback
];

// NOTE could get this from an outside source since it changes slightly over time
export const BLOCK_RATE_SECONDS = 13.14;

export const TOKEN_DECIMALS = 9;

export const addresses = {
  31337: {
    DAI_ADDRESS: "0x6b175474e89094c44da98b954eedeac495271d0f", // duplicate
    OHM_ADDRESS: data.ASG_address,
    STAKING_ADDRESS: data.Staking_address, // The new staking contract
    STAKING_HELPER_ADDRESS: data.stakingHelper_address, // Helper contract used for Staking only
    OLD_STAKING_ADDRESS: "0x0822F3C03dcc24d200AFF33493Dc08d0e1f274A2", //
    SOHM_ADDRESS: data.sASG_address, //
    OLD_SOHM_ADDRESS: "0x31932E6e45012476ba3A3A4953cbA62AeE77Fbbe", //
    PRESALE_ADDRESS: "0xcBb60264fe0AC96B0EFa0145A9709A825afa17D8", //
    AOHM_ADDRESS: "0x24ecfd535675f36ba1ab9c5d39b50dc097b0792e", //
    MIGRATE_ADDRESS: "0xC7f56EC779cB9e60afA116d73F3708761197dB3d", //
    LPSTAKING_ADDRESS: "0xF11f0F078BfaF05a28Eac345Bb84fcb2a3722223", //
    LP_ADDRESS: data.LPDAItokenSushi_address,
    DISTRIBUTOR_ADDRESS: data.distributor_Address,
    // BOND_ADDRESS: "0x996668C46Fc0B764aFdA88d83eB58afc933a1626", // latest "v1" (deprecated)
    BONDINGCALC_ADDRESS: data.AsgardBondingCalculator_address, //
    LP_BONDINGCALC_ADDRESS: data.AsgardBondingCalculator_address, //
    // DAI_BOND_ADDRESS: "0xD03056323b7a63e2095AE97fA1AD92E4820ff045", // latest "v1" (deprecated)
    CIRCULATING_SUPPLY_ADDRESS: data.circulating_address,
    // OHM_FRAX_BOND_ADDRESS: "0x539b6c906244Ac34E348BbE77885cdfa994a3776", // v1 OHM_FRAX (deprecated)
    TREASURY_ADDRESS: data.treasuryAddress,

    // This is V1.1. Some are copied from above.
    RESERVES: {
      DAI: "0x6b175474e89094c44da98b954eedeac495271d0f",
      OHM_DAI: data.LPDAItokenSushi_address,
      OHM_FRAX: data.LPFRAXtokenSushi_address,
      FRAX: data.FRAX,
      UST: data.WUST,
      OHM_UST: data.LPwUSTtokenSushi_address,
    },

    BONDS: {
      OHM_DAI_CALC: data.AsgardBondingCalculator_address, // this should be handled with new bond calc
      OHM_DAI: data.ASG_DAIBond_address, // v1.1 updated
      DAI: data.DAIBond_address, // v1.1 updated
      OHM_FRAX: data.ASG_FRAXBond_address, // v1.1 updated
      FRAX: data.FRAXBond_address, // FRAX Bonds
      UST: data.wUSTBond_address, // UST Bonds
      OHM_UST: data.ASG_wUSTBond_address,
    },

    FAIR_BONDS: {
      OHM_DAI_CALC: data.AsgardBondingCalculator_address, // this should be handled with new bond calc
      OHM_DAI: data.F_ASG_DAIBond_address, // v1.1 updated
      DAI: data.F_DAIBond_address, // v1.1 updated
      OHM_FRAX: data.F_ASG_FRAXBond_address, // v1.1 updated
      FRAX: data.F_FRAXBond_address, // FRAX Bonds
    },
  },
  1: {
    DAI_ADDRESS: "0x6b175474e89094c44da98b954eedeac495271d0f", // duplicate
    OHM_ADDRESS: data.ASG_address,
    STAKING_ADDRESS: data.Staking_address, // The new staking contract
    STAKING_HELPER_ADDRESS: data.stakingHelper_address, // Helper contract used for Staking only
    OLD_STAKING_ADDRESS: "0x0822F3C03dcc24d200AFF33493Dc08d0e1f274A2", //
    SOHM_ADDRESS: data.sASG_address, //
    OLD_SOHM_ADDRESS: "0x31932E6e45012476ba3A3A4953cbA62AeE77Fbbe", //
    PRESALE_ADDRESS: "0xcBb60264fe0AC96B0EFa0145A9709A825afa17D8", //
    AOHM_ADDRESS: "0x24ecfd535675f36ba1ab9c5d39b50dc097b0792e", //
    MIGRATE_ADDRESS: "0xC7f56EC779cB9e60afA116d73F3708761197dB3d", //
    LPSTAKING_ADDRESS: "0xF11f0F078BfaF05a28Eac345Bb84fcb2a3722223", //
    LP_ADDRESS: data.LPDAItokenSushi_address,
    DISTRIBUTOR_ADDRESS: data.distributor_Address,
    // BOND_ADDRESS: "0x996668C46Fc0B764aFdA88d83eB58afc933a1626", // latest "v1" (deprecated)
    BONDINGCALC_ADDRESS: data.AsgardBondingCalculator_address, //
    LP_BONDINGCALC_ADDRESS: data.AsgardBondingCalculator_address, //
    // DAI_BOND_ADDRESS: "0xD03056323b7a63e2095AE97fA1AD92E4820ff045", // latest "v1" (deprecated)
    CIRCULATING_SUPPLY_ADDRESS: data.circulating_address,
    // OHM_FRAX_BOND_ADDRESS: "0x539b6c906244Ac34E348BbE77885cdfa994a3776", // v1 OHM_FRAX (deprecated)
    TREASURY_ADDRESS: data.treasuryAddress,

    // This is V1.1. Some are copied from above.
    RESERVES: {
      DAI: "0x6b175474e89094c44da98b954eedeac495271d0f",
      OHM_DAI: data.LPDAItokenSushi_address,
      OHM_FRAX: data.LPFRAXtokenSushi_address,
      FRAX: data.FRAX,
      UST: data.WUST,
      OHM_UST: data.LPwUSTtokenSushi_address,
    },

    BONDS: {
      OHM_DAI_CALC: data.AsgardBondingCalculator_address, // this should be handled with new bond calc
      OHM_DAI: data.ASG_DAIBond_address, // v1.1 updated
      DAI: data.DAIBond_address, // v1.1 updated
      OHM_FRAX: data.ASG_FRAXBond_address, // v1.1 updated
      FRAX: data.FRAXBond_address, // FRAX Bonds
      UST: data.wUSTBond_address, // UST Bonds
      OHM_UST: data.ASG_wUSTBond_address,
    },

    FAIR_BONDS: {
      OHM_DAI_CALC: data.AsgardBondingCalculator_address, // this should be handled with new bond calc
      OHM_DAI: data.F_ASG_DAIBond_address, // v1.1 updated
      DAI: data.F_DAIBond_address, // v1.1 updated
      OHM_FRAX: data.F_ASG_FRAXBond_address, // v1.1 updated
      FRAX: data.F_FRAXBond_address, // FRAX Bonds
    },
  },
};

export const BONDS = {
  ohm_dai: "asg_dai_lp",
  ohm_frax: "asg_frax_lp",
  ohm_ust: "asg_ust_lp",
  dai: "dai",
  frax: "frax",
  ust: "ust",
  dai_v1: "dai_v1",
  ohm_dai_v1: "ohm_dai_lp_v1",
  ohm_frax_v1: "ohm_frax_v1",
};

export const Actions = {
  FETCH_ACCOUNT_SUCCESS: "account/FETCH_ACCOUNT_SUCCESS",
  FETCH_APP_SUCCESS: "app/FETCH_APP_SUCCESS",
  FETCH_STAKE_SUCCESS: "stake/FETCH_STAKE_SUCCESS",
  FETCH_BOND_SUCCESS: "bond/FETCH_BOND_SUCCESS",
  FETCH_BOND_FAIR_LAUNCH_SUCCESS: "bond-fair-launch/FETCH_BOND_SUCCESS",
  FETCH_MIGRATE_SUCCESS: "migrate/FETCH_MIGRATE_SUCCESS",
  FETCH_FRAX_SUCCESS: "FETCH_FRAX_SUCCESS",
  FETCH_SWAP_SUCCESS: "swap/FETCH_SWAP_SUCCESS",
};

// MY ETHERSCAN_ID, SWAP IN YOURS FROM https://etherscan.io/myapikey
export const ETHERSCAN_KEY = "PSW8C433Q667DVEX5BCRMGNAH9FSGFZ7Q8";

// BLOCKNATIVE ID FOR Notify.js:
export const BLOCKNATIVE_DAPPID = "0b58206a-f3c0-4701-a62f-73c7243e8c77";

// EXTERNAL CONTRACTS

export const DAI_ADDRESS = "0x6B175474E89094C44Da98b954EedeAC495271d0F";

export const DAI_ABI = [
  {
    inputs: [{ internalType: "uint256", name: "chainId_", type: "uint256" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "src", type: "address" },
      { indexed: true, internalType: "address", name: "guy", type: "address" },
      { indexed: false, internalType: "uint256", name: "wad", type: "uint256" },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: true,
    inputs: [
      { indexed: true, internalType: "bytes4", name: "sig", type: "bytes4" },
      { indexed: true, internalType: "address", name: "usr", type: "address" },
      { indexed: true, internalType: "bytes32", name: "arg1", type: "bytes32" },
      { indexed: true, internalType: "bytes32", name: "arg2", type: "bytes32" },
      { indexed: false, internalType: "bytes", name: "data", type: "bytes" },
    ],
    name: "LogNote",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "src", type: "address" },
      { indexed: true, internalType: "address", name: "dst", type: "address" },
      { indexed: false, internalType: "uint256", name: "wad", type: "uint256" },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    constant: true,
    inputs: [],
    name: "DOMAIN_SEPARATOR",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "PERMIT_TYPEHASH",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "", type: "address" },
    ],
    name: "allowance",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "address", name: "usr", type: "address" },
      { internalType: "uint256", name: "wad", type: "uint256" },
    ],
    name: "approve",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "address", name: "usr", type: "address" },
      { internalType: "uint256", name: "wad", type: "uint256" },
    ],
    name: "burn",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ internalType: "address", name: "guy", type: "address" }],
    name: "deny",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "address", name: "usr", type: "address" },
      { internalType: "uint256", name: "wad", type: "uint256" },
    ],
    name: "mint",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "address", name: "src", type: "address" },
      { internalType: "address", name: "dst", type: "address" },
      { internalType: "uint256", name: "wad", type: "uint256" },
    ],
    name: "move",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "name",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "nonces",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "address", name: "holder", type: "address" },
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "nonce", type: "uint256" },
      { internalType: "uint256", name: "expiry", type: "uint256" },
      { internalType: "bool", name: "allowed", type: "bool" },
      { internalType: "uint8", name: "v", type: "uint8" },
      { internalType: "bytes32", name: "r", type: "bytes32" },
      { internalType: "bytes32", name: "s", type: "bytes32" },
    ],
    name: "permit",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "address", name: "usr", type: "address" },
      { internalType: "uint256", name: "wad", type: "uint256" },
    ],
    name: "pull",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "address", name: "usr", type: "address" },
      { internalType: "uint256", name: "wad", type: "uint256" },
    ],
    name: "push",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ internalType: "address", name: "guy", type: "address" }],
    name: "rely",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "symbol",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "address", name: "dst", type: "address" },
      { internalType: "uint256", name: "wad", type: "uint256" },
    ],
    name: "transfer",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "address", name: "src", type: "address" },
      { internalType: "address", name: "dst", type: "address" },
      { internalType: "uint256", name: "wad", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "version",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "wards",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
];

export const NETWORK = chainId => {
  for (const n in NETWORKS) {
    if (NETWORKS[n].chainId === chainId) {
      return NETWORKS[n];
    }
  }
};

export const NETWORKS = {
  localhost: {
    name: "localhost",
    color: "#666666",
    chainId: 31337,
    blockExplorer: "",
    rpcUrl: "http://testdao.eu.ngrok.io",
  },
  mainnet: {
    name: "mainnet",
    color: "#ff8b9e",
    chainId: 1,
    rpcUrl: `https://mainnet.infura.io/v3/63e7f195cd914293a756752e45d1e6fe`,
    blockExplorer: "https://etherscan.io/",
  },
  kovan: {
    name: "kovan",
    color: "#7003DD",
    chainId: 42,
    rpcUrl: `https://kovan.infura.io/v3/${INFURA_ID}`,
    blockExplorer: "https://kovan.etherscan.io/",
    faucet: "https://gitter.im/kovan-testnet/faucet", // https://faucet.kovan.network/
  },
  rinkeby: {
    name: "rinkeby",
    color: "#e0d068",
    chainId: 4,
    rpcUrl: `https://rinkeby.infura.io/v3/${INFURA_ID}`,
    faucet: "https://faucet.rinkeby.io/",
    blockExplorer: "https://rinkeby.etherscan.io/",
  },
  ropsten: {
    name: "ropsten",
    color: "#F60D09",
    chainId: 3,
    faucet: "https://faucet.ropsten.be/",
    blockExplorer: "https://ropsten.etherscan.io/",
    rpcUrl: `https://ropsten.infura.io/v3/${INFURA_ID}`,
  },
  goerli: {
    name: "goerli",
    color: "#0975F6",
    chainId: 5,
    faucet: "https://goerli-faucet.slock.it/",
    blockExplorer: "https://goerli.etherscan.io/",
    rpcUrl: `https://goerli.infura.io/v3/${INFURA_ID}`,
  },
  xdai: {
    name: "xdai",
    color: "#48a9a6",
    chainId: 100,
    price: 1,
    gasPrice: 1000000000,
    rpcUrl: "https://dai.poa.network",
    faucet: "https://xdai-faucet.top/",
    blockExplorer: "https://blockscout.com/poa/xdai/",
  },
  matic: {
    name: "matic",
    color: "#2bbdf7",
    chainId: 137,
    price: 1,
    gasPrice: 1000000000,
    rpcUrl: "https://rpc-mainnet.maticvigil.com",
    faucet: "https://faucet.matic.network/",
    blockExplorer: "https://explorer-mainnet.maticvigil.com//",
  },
  mumbai: {
    name: "mumbai",
    color: "#92D9FA",
    chainId: 80001,
    price: 1,
    gasPrice: 1000000000,
    rpcUrl: "https://rpc-mumbai.maticvigil.com",
    faucet: "https://faucet.matic.network/",
    blockExplorer: "https://mumbai-explorer.matic.today/",
  },
};
